import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { ICartItem } from 'src/app/cart-common/model';
import { ICart } from 'src/app/cart-common/model/cart';
import { CoreConfiguration } from 'src/app/core/core-configuration';
import { PaymentType } from 'src/app/payment/model';
import { CartCookieService } from 'src/app/shared/service/cart-cookie-service.service';
import { CaptureEmailModel } from '../../model/capture-email.model';
import { CheckoutAuthenticateModel } from '../../model/checkout-authenticate.model';
import { Constants } from '../../model/constants';
import { CustomerData } from '../../model/customer-data.model';
import { TrackingService } from '../../service/tracking.service';

declare var gtag: any;
declare global {
  interface Window {
    gtag: any;
    dataLayer: any;
  }
}

@Component({
  selector: 'lef-global-site-tag',
  templateUrl: './global-site-tag.component.html',
  styleUrls: ['./global-site-tag.component.scss'],
})
export class GlobalSiteTagComponent implements OnInit {
  private GA_MEASUREMENT_ID: string;
  constructor(
    private readonly renderer: Renderer2,
    private readonly trackingService: TrackingService,
    private readonly config: CoreConfiguration,
    private readonly cartCookieService: CartCookieService
  ) { }

  ngOnInit(): void {
    if (this.config.environment.enableGoogleTagTracking) {
      this.GA_MEASUREMENT_ID = this.config.environment.googleUIId;
      this.trackingService.customerDataChanged.subscribe({ next: (customer) => this.onCustomerDataChanged(customer) });
      this.trackingService.customerNumberChanged.subscribe({ next: (customerNumber) => this.onCustomerNumberChanged(customerNumber) });
      this.trackingService.navigationChanged.subscribe({ next: (event) => this.onNavigationChanged(event) });

      this.trackingService.orderPlaced.subscribe({ next: (cart) => this.onOrderPlaced(cart) });
      this.trackingService.itemAddedToCart.subscribe({ next: (item) => this.onItemAddedToCart(item) });
      this.trackingService.itemsAddedToCart.subscribe({ next: (items) => this.onItemsAddedToCart(items) });
      this.trackingService.itemRemovedFromCart.subscribe({ next: (item) => this.onItemRemovedFromCart(item) });
      this.trackingService.itemsRemovedFromCart.subscribe({ next: (items) => this.onItemsRemovedFromCart(items) });

      this.trackingService.viewCart.subscribe({ next: (cart) => this.onViewCart(cart) });
      this.trackingService.checkoutBegin.subscribe({ next: (cart) => this.onFunnelCheckoutBegin(cart) });
      this.trackingService.checkoutAuthenticate.subscribe({ next: (cart) => this.onCheckoutAuthenticate(cart) });
      this.trackingService.checkoutAddShippingInfo.subscribe({ next: (cart) => this.onCheckoutAddShippingInfo(cart) });
      this.trackingService.checkoutAddPaymentInfo.subscribe({ next: (cart) => this.onCheckoutAddPaymentInfo(cart) });
      this.trackingService.captureEmail.subscribe({ next: (CaptureEmail) => this.onCaptureEmail(CaptureEmail) });
      this.trackingService.signUp.subscribe({ next: (prompt) => this.onSignUpAndLogIn(prompt, 'sign_up') });
      this.trackingService.logIn.subscribe({ next: (prompt) => this.onSignUpAndLogIn(prompt, 'login') });
      this.trackingService.logOut.subscribe({ next: (prompt) => this.onLogOut() });
      this.trackingService.generateLead.subscribe({ next: () => this.onGenerateLead() });
      this.trackingService.addToWishList.subscribe({ next: (item) => this.onAddToWishList(item) });

      this.addTag('js', new Date());
      this.addTag('config', 'AW-1071920708');
      this.sendCustomerUpdate();
    }
  }

  private onCustomerNumberChanged(customerNumber: string) {
    this.addTag('config', this.GA_MEASUREMENT_ID, {
      user_id: customerNumber,
      cookie_domain: '.lifeextension.com',
      custom_map: {
        dimension4: 'customerRecurrence',
        dimension5: 'customerType',
        dimension6: 'retentionStage',
      },
    });
    this.addTag('config', 'G-L7F9CKYJVX', {
      user_id: customerNumber,
      cookie_domain: '.lifeextension.com',
    });
  }

  private onCustomerDataChanged(customer: CustomerData) {
    if (!customer) return;

    this.addTag('event', 'customer_data', {
      non_interaction: true,
      customerRecurrence: customer.customerRecurrence,
      customerType: customer.customerType,
      retentionStage: customer.retentionStage,
    });

    this.sendCustomerUpdate();
    this.onDataLayerUpdate();
  }

  private onNavigationChanged(event: NavigationEnd) {
    this.addTag('event', 'page_view', {
      page_path: event.urlAfterRedirects,
      send_to: this.GA_MEASUREMENT_ID,
    });
  }

  private onOrderPlaced(cart: ICart) {
    this.addConversionEvent(cart);
    this.checkoutOnOrderPlaced(cart);
  }

  private onItemAddedToCart(item: ICartItem): void {
    if (!item) return;
    this.onItemsAddedToCart([item]);
  }

  private onItemsAddedToCart(items: ICartItem[]): void {
    if (!items?.length) return;

    const data = {
      event: 'addToCart',
      ContentGroup: 'Cart',
      Status: this.getCustomerStatus(),
      has_autoship_item: items?.some((i) => i?.isAutoShip),
      ecommerce: {
        currencyCode: 'USD',
        add: {
          products: [],
        },
      },
    };

    items.forEach((item) => {
      data.ecommerce.add.products.push({
        item_name: this.concatItemName(item),
        item_id: item.itemNumber,
        price: item.yourPrice,
        item_brand: item.brand,
        item_category: item.primaryHealthConcern,
        item_variant: item.isAutoShip ? 'autoship' : 'onetime',
        quantity: item.quantity,
      });
    });

    window?.dataLayer?.push(data);
  }

  private onItemRemovedFromCart(item: ICartItem): void {
    if (!item) return;
    this.onItemsRemovedFromCart([item]);
  }

  private onItemsRemovedFromCart(items: ICartItem[]): void {
    if (!items?.length) return;

    const data = {
      event: 'removeFromCart',
      ContentGroup: 'Cart',
      Status: this.getCustomerStatus(),
      has_autoship_item: items?.some((i) => i?.isAutoShip),
      ecommerce: {
        currencyCode: 'USD',
        remove: {
          products: [],
        },
      },
    };

    items.forEach((item) => {
      data.ecommerce.remove.products.push({
        name: this.concatItemName(item),
        id: item.itemNumber,
        price: item.yourPrice,
        brand: item.brand,
        category: item.primaryHealthConcern,
        variant: item.isAutoShip ? 'autoship' : 'onetime',
        quantity: item.quantity,
      });
    });

    window?.dataLayer?.push(data);
  }

  private onViewCart(cart: ICart) {
    if (cart == undefined || cart == null) return;

    const data = {
      event: 'view_cart',
      ContentGroup: 'Cart',
      Status: this.getCustomerStatus(),
      has_autoship_item: cart?.items?.some((i) => i?.isAutoShip),
      ecommerce: {
        currency: 'USD',
        value: cart?.totalOrder,
        items: this.getFunnelItemsEventData(cart.items),
      },
    };
    window?.dataLayer?.push(data);
  }

  private onFunnelCheckoutBegin(cart: ICart) {
    if (cart == undefined || cart == null) return;

    if (this.validateChekOutEventOnLocalStorage('checkoutBegin')) {
      const data = {
        event: 'begin_checkout',
        ContentGroup: 'Cart',
        Status: this.getCustomerStatus(),
        has_autoship_item: cart?.items?.some((i) => i?.isAutoShip),
        ecommerce: {
          currency: 'USD',
          value: cart?.totalOrder,
          coupon: cart?.marketingMessages[0]?.discountCode ?? '',
          items: this.getFunnelItemsEventData(cart.items),
        },
      };
      window?.dataLayer?.push(data);
    }
  }

  private onCheckoutAuthenticate(checkoutAuthenticate: CheckoutAuthenticateModel) {
    if (checkoutAuthenticate.cart == undefined || checkoutAuthenticate.cart == null) return;

    if (this.validateChekOutEventOnLocalStorage('checkoutAuthenticate')) {
      const data = {
        event: 'authenticate',
        ContentGroup: 'Cart',
        Status: this.getCustomerStatus(),
        has_autoship_item: checkoutAuthenticate?.cart?.items?.some((i) => i?.isAutoShip),
        type: checkoutAuthenticate?.authenticateType,
        ecommerce: {
          currency: 'USD',
          value: checkoutAuthenticate.cart?.totalOrder,
          coupon: checkoutAuthenticate.cart?.marketingMessages[0]?.discountCode ?? '',
          items: this.getFunnelItemsEventData(checkoutAuthenticate.cart.items),
        },
      };

      window?.dataLayer?.push(data);
    }
  }

  private onCheckoutAddShippingInfo(cart: ICart) {
    if (cart == undefined || cart == null) return;

    if (this.validateChekOutEventOnLocalStorage('checkoutAddShippingInfo')) {
      const data = {
        event: 'add_shipping_info',
        ContentGroup: 'Cart',
        Status: this.getCustomerStatus(),
        has_autoship_item: cart?.items?.some((i) => i?.isAutoShip),
        ecommerce: {
          currency: 'USD',
          value: cart?.totalOrder,
          coupon: cart?.marketingMessages[0]?.discountCode ?? '',
          shipping_tier: cart?.shipping?.shippingMethod?.description,
          items: this.getFunnelItemsEventData(cart.items),
          tax: cart?.totalTaxes ?? 0
        },
      };

      window?.dataLayer?.push(data);
    }
  }

  private onCheckoutAddPaymentInfo(cart: ICart) {
    if (cart == undefined || cart == null) return;

    if (this.validateChekOutEventOnLocalStorage('checkoutAddPaymentInfo')) {
      const data = {
        event: 'add_payment_info',
        ContentGroup: 'Cart',
        Status: this.getCustomerStatus(),
        has_autoship_item: cart?.items?.some((i) => i?.isAutoShip),
        ecommerce: {
          currency: 'USD',
          value: cart?.totalOrder,
          coupon: cart?.marketingMessages[0]?.discountCode ?? '',
          payment_type: this.getPaymentType(cart),
          items: this.getFunnelItemsEventData(cart.items),
          tax: cart?.totalTaxes ?? 0
        },
      };

      window?.dataLayer?.push(data);
    }
  }

  private checkoutOnOrderPlaced(cart: ICart) {
    if (cart == undefined || cart == null) return;
    this.onCheckoutAddPaymentInfo(cart);

    const country = cart?.shipping?.selectedAddress?.countryCode;
    const customer = this.trackingService.getCurrentCustomerData();

    if (this.validateChekOutEventOnLocalStorage('purchase')) {
      let data = {
        event: 'purchase',
        ContentGroup: 'Cart',
        Status: this.getCustomerStatus(),
        has_autoship_item: cart?.items?.some((i) => i?.isAutoShip),
        CustomerCountry: country === 'US' || country === 'CA' ? country : '',
        CustomerStatus: customer?.customerRecurrence === 'New' ? 'New' : 'Return',
        Location: country === 'US' || country === 'CA' ? cart?.shipping?.selectedAddress?.state : '',
        LoyaltyStatus: customer?.customerType === 'P' ? 'Yes' : 'No',
        ecommerce: {
          currency: 'USD',
          transaction_id: cart?.orderNumber,
          value: cart?.totalOrder,
          coupon: cart?.marketingMessages[0]?.discountCode ?? '',
          shipping: cart?.totalFreight,
          tax: cart?.totalTaxes ?? 0,
          shipping_tier: cart?.shipping?.shippingMethod?.description,
          items: this.getFunnelItemsEventData(cart.items),
        },
      };

      window?.dataLayer?.push(
        cart?.marketingDiscountItem ? { ...data, OrderDiscount: Math.abs(cart?.marketingDiscountItem?.yourPrice) } : data
      );
    }
  }

  private onCaptureEmail(captureEmail: CaptureEmailModel) {
    if (captureEmail == undefined || captureEmail == null) return;

    const data = {
      event: 'capture_email',
      ContentGroup: 'Cart',
      vehicle: captureEmail.vehicle,
      status: captureEmail.status,
      program: 'Web Account',
    };

    window?.dataLayer?.push(data);
  }

  private onSignUpAndLogIn(prompt: string, event: string) {
    if (prompt == undefined || prompt == null) return;

    const data = {
      event: event,
      ContentGroup: 'Cart',
      Status: this.getCustomerStatus(),
      method: 'cart',
      prompt: prompt,
    };

    window?.dataLayer?.push(data);
  }

  private onLogOut() {
    localStorage.removeItem(Constants.TrackingStorageKey);
    this.cartCookieService.setContentSiteCookie(this.config.cookies.lifeExtensionUser, '', 0, 0, 30, null, true, 'None');
  }

  private onGenerateLead() {
    const data = {
      event: 'generate_lead',
      ContentGroup: 'Cart',
      Status: this.getCustomerStatus(),
      vehicle: 'Checkout',
      program: 'CHK',
    };

    window?.dataLayer?.push(data);
  }

  private onAddToWishList(item: ICartItem) {
    const data = {
      event: 'add_to_wishlist',
      item_name: this.concatItemName(item),
      value: item.yourPrice,
    };

    window?.dataLayer?.push(data);
  }

  private sendCustomerUpdate() {
    const cookie = this.cartCookieService.getCookieObject();
    if (!cookie) return;

    let currentCustomer = this.trackingService.getCurrentCustomerData();
    // More Visibility
    const data = {
      Status: this.getCustomerStatus(),
      ContentGroup: 'Cart',
      CustomerNumber: cookie[this.config.cartCookieKeys.customerNumber],
      CustomerType: cookie[this.config.cartCookieKeys.customerType],
      WholesalePricingGroup: currentCustomer.wholesalerPriceGroup,
      Email: currentCustomer.hashEmail,
      FirstName: currentCustomer.hashFirstName,
      LastName: currentCustomer.hashLastName,
      PhoneNumber: currentCustomer.hashPhoneNumber,
      ZipCode: currentCustomer.hashZipCode,
      CustomerFirstSaleDate: cookie[this.config.cartCookieKeys.customerNumber] ? currentCustomer.customerFirstSaleDate : undefined,
      ExperianPersona: cookie[this.config.cartCookieKeys.customerNumber] ? currentCustomer.experianPersona : undefined,
      IsAutoShipCustomer: currentCustomer?.isAutoShipCustomer?.toLocaleLowerCase(),
      IsPremierAutoRenew: currentCustomer?.isPremierAutoRenew?.toLocaleLowerCase(),
      IsSMS: currentCustomer?.isSMS?.toLocaleLowerCase(),
      LifecycleGroup: cookie[this.config.cartCookieKeys.customerNumber] ? currentCustomer.lifeCycleGroup : undefined,
      WholesaleType: currentCustomer.wholesaleType,
      WholesaleTypeGroup: currentCustomer.wholesaleTypeGroup,
    };

    window?.dataLayer?.push(data);
  }

  private onDataLayerUpdate(): void {
    const data = { event: 'dataLayer_update' };
    window?.dataLayer?.push(data);
  }
  // ----------------------------------------------------------

  private addTag(key: string, value: any, parameters?: any) {
    if (!window.gtag) {
      return;
    }

    if (parameters !== undefined) {
      gtag(key, value, parameters);
    } else {
      gtag(key, value);
    }
  }

  private addConversionEvent(cart: ICart) {
    const customer = this.trackingService.getCurrentCustomerData();
    this.addTag('event', 'conversion', {
      send_to: 'AW-1071920708/rNSwCOrdg8kBEMTskP8D',
      value: cart.totalOrder,
      currency: 'USD',
      transaction_id: `${cart.orderNumber}`,
      new_customer: customer.customerRecurrence === 'New' ? true : false,
    });
  }


  private getItemsEventData(cart: ICart): any[] {
    const items: any[] = [];
    cart.items
      .filter((i) => i.lineTye !== 'F')
      .forEach((item) => {
        items.push({
          id: item.itemNumber,
          name: this.concatItemName(item),
          brand: item.brand,
          category: item.primaryCategory,
          quantity: item.quantity,
          price: item.yourPrice,
          variant: item.isAutoShip ? 'autoship' : 'onetime',
          list_position: items.length + 1,
          list_name: 'cart',
        });
      });
    return items;
  }

  private getFunnelItemsEventData(items: ICartItem[]): any[] {
    const itemsData: any[] = [];
    items.forEach((item) => {
      itemsData.push({
        item_brand: item.brand,
        item_category: item.primaryHealthConcern,
        item_id: item.itemNumber,
        item_name: this.concatItemName(item),
        price: item.yourPrice,
        quantity: item.quantity,
        item_variant: item.isAutoShip ? 'autoship' : 'onetime',
      });
    });
    return itemsData;
  }

  private getPurchaseEventData(cart: ICart): any {
    return {
      transaction_id: `${cart.orderNumber}`,
      affiliation: 'Life Extension',
      value: cart.totalOrder,
      currency: 'USD',
      tax: 0.0,
      shipping: cart.totalFreight,
      items: this.getItemsEventData(cart),
    };
  }

  private getPaymentType(cart: ICart): string {
    if (cart?.totalOrder === cart?.rewardsApplied) return 'Rewards';
    if (cart?.paymentOptionSelected?.type === PaymentType.OnAccount) return `On account`;
    if (cart?.paymentOptionSelected?.type === PaymentType.CreditCard) return `Credit card`;
    if (cart?.paymentOptionSelected?.type === PaymentType.PayPal) return `PayPal`;
    if (cart?.paymentOptionSelected?.type === PaymentType.ApplePay) return `Apple Pay`;
    if (cart?.paymentOptionSelected?.type === PaymentType.GooglePay) return `Google Pay`;
    return '';
  }

  private validateChekOutEventOnLocalStorage(event: string): boolean {
    let currentTrackingId = this.trackingService.storage.get(Constants.TrackingId);
    let trackingStorageKey = Constants.TrackingStorageKey;
    if (!localStorage.getItem(trackingStorageKey)?.includes(currentTrackingId)) {
      localStorage.removeItem(trackingStorageKey);
    }

    if (event === 'purchase') {
      localStorage.removeItem(trackingStorageKey);
      return true;
    }

    if (localStorage.getItem(trackingStorageKey) === null) {
      localStorage.setItem(trackingStorageKey, `${currentTrackingId},${event}`);
      return true;
    }

    let doneEvents = localStorage.getItem(trackingStorageKey);
    if (doneEvents.includes(event)) return false;

    localStorage.setItem(trackingStorageKey, `${doneEvents},${event}`);
    return true;
  }

  private getCustomerStatus(): string {
    let customerData = this.trackingService.getCurrentCustomerData();
    if (customerData.customerNumber && customerData.customerNumber !== '0' && customerData.customerType == 'AN') return 'Prospect';
    if (customerData.customerNumber && customerData.customerNumber !== '0' && !customerData.customerFirstSaleDate) return 'Prospect';
    if (customerData.customerNumber && customerData.customerNumber !== '0') return 'Customer';
    return 'New';
  }

  private concatItemName(item: ICartItem): string {
    return item?.nameSuffix?.length > 0 ? `${item?.itemDescription}, ${item?.nameSuffix}` : item?.friendlyDescription;
  }
}
